import {MutableRef} from "preact/hooks";
import {computed, signal} from "@preact/signals";
import Memoizable from "./Memoizable";

export default Memoizable(class MapController {
  readonly ref: MutableRef<SVGSVGElement> = {current: null as any}
  readonly renderScale = signal(
    ["Android", "iPhone", "iPad", "iPod"].some((device) => navigator.userAgent.includes(device)) ? 2 : 8
  )
  readonly transform = signal({x: 0, y: 0, z: 1})
  readonly movementStates = {
    dragging: signal(false),
    zooming: signal(false),
    pinchZooming: signal(false),
    wheelZooming: signal(false),
    lastPinchDistance: signal(0),
  }
  readonly dragMove = signal({lastPos: {x: 0, y: 0}, lastEventPassed: false})
  #dragTick = 0
  dragTickInterval = 3
  readonly isAtInitialPosition = computed(() =>
    this.transform.value.x === 0 && this.transform.value.y === 0 && this.transform.value.z === 1
  )
  
  get tickTrigger() {
    this.#dragTick++
    if (this.#dragTick > this.dragTickInterval) this.#dragTick = 0
    return this.#dragTick % this.dragTickInterval === 0
  }
  
  reset() {
    this.transform.value = {x: 0, y: 0, z: 1}
    this.dragMove.value = {lastPos: {x: 0, y: 0}, lastEventPassed: false}
  }
})