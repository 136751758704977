import Memoizable from "./Memoizable";
import {signal, Signal} from "@preact/signals";


export default Memoizable(class InteractiveStadiumData {
  // Zones management
  zones: Signal<InteractiveStadiumZone[]>;
  zoomedZoneId = signal(undefined as number | undefined);
  zoomedZoneData?: ZoneData

  // Places management
  places = signal([] as MapPlaceData[]);

  constructor(initZones: InteractiveStadiumZone[]) {
    this.zones = signal(initZones)
  }

  // This function will reset the zone state
  resetZoneState() {
    if (this.places.value.length) this.places.value = []
    this.zoomedZoneId.value = undefined
  }

})