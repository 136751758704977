import Thrower from "../../lib/thrower";

export default class Tooltip extends Thrower {
  element: HTMLElement;
  parent: HTMLElement;

  constructor(element: HTMLElement, parent?: HTMLElement) {
    super();
    this.element = element;
    this.parent = parent || element.parentElement || document.body;
    if (this.element.classList.contains("tooltip")) return;
    this.element.classList.add("tooltip");
  }

  set html(html: string) {
    this.element.innerHTML = html;
  }

  setHtml(html: string) {
    this.html = html;
    return this;
  }

  showOn(el: HTMLElement | SVGElement) {
    const offset = el.offsetFrom(this.parent) || this.throw(`Cannot get offset between ${this.parent} and ${el} to place tooltip`);
    this.element.style.setProperty("--pos-x", offset.left + offset.child.width / 2 + "px");
    this.element.style.setProperty("--pos-y", offset.top + offset.child.height / 2 + "px");
    return this;
  }

  fitIn(el?: HTMLElement | SVGElement, margin = [0]) {
    el ||= this.parent;
    const margin_top = margin[0] || 0;
    const margin_right = margin[1] || margin[0] || 0;
    const margin_bottom = margin[2] || margin[0] || 0;
    const margin_left = margin[3] || margin[1] || margin[0] || 0;

    const offset = el.offsetFrom(this.element) || this.throw(`Cannot get offset between ${this.element} and ${el} to place tooltip`);

    let potentialTranslateX = 0;
    if (-offset.left < margin_left) potentialTranslateX = margin_left + offset.left;
    if (offset.right < margin_right) potentialTranslateX = -(margin_right - offset.right);

    let potentialTranslateY = 0;
    if (-offset.top < margin_top) potentialTranslateY = margin_top + offset.top;
    if (offset.bottom < margin_bottom) potentialTranslateY = -(margin_bottom - offset.bottom);

    let hasFlippedX = false;
    let hasFlippedY = false;

    if ((
      this.element.classList.contains("top") ||
      ["bottom", "left", "right"].every(c => !this.element.classList.contains(c))
    ) && potentialTranslateY > 0) {
      this.element.classList.remove("top");
      this.element.classList.add("bottom");
      hasFlippedY = true;
    }
    if (this.element.classList.contains("bottom") && potentialTranslateY < 0) {
      this.element.classList.remove("bottom");
      this.element.classList.add("top");
      hasFlippedY = true;
    }
    if (this.element.classList.contains("left") && potentialTranslateX > 0) {
      this.element.classList.remove("left");
      this.element.classList.add("right");
      hasFlippedX = true;
    }
    if (this.element.classList.contains("right") && potentialTranslateX < 0) {
      this.element.classList.remove("right");
      this.element.classList.add("left");
      hasFlippedX = true;
    }


    if (!hasFlippedX && potentialTranslateX) {
      this.element.style.setProperty("--pos-x", parseFloat(this.element.style.getPropertyValue("--pos-x")) + potentialTranslateX + "px");
      this.element.style.setProperty("--arrow-offset", (-potentialTranslateX) + "px");
    } else if (!hasFlippedY && potentialTranslateY) {
      this.element.style.setProperty("--pos-y", parseFloat(this.element.style.getPropertyValue("--pos-y")) + potentialTranslateY + "px");
      this.element.style.setProperty("--arrow-offset", (-potentialTranslateY) + "px");
    } else {
      this.element.style.setProperty("--arrow-offset", "0px");
    }

    return this;
  }

  show() {
    this.element.classList.add("show");
    return this;
  }

  hide() {
    this.element.classList.remove("show");
    return this;
  }
}