import {useMemo} from "react";
import {memo} from "preact/compat";
import {FunctionalComponent, FunctionComponent} from "preact";

/**
 * A hook that memoizes a pure component, to permit his declaration inside a functional component.
 * UseMemo is used to memoise the result of the memo function, to avoid to create a new memoized component at each render.
 */
export function useMemoizedPureComponent<P = {}>(component: FunctionalComponent<P>) {
  return useMemo<FunctionComponent<P>>(() => memo(component), []);
}