import {computed, Signal, signal} from "@preact/signals";
import {get} from "../../lib/request";
import {ConfiguratorProps} from "../Configurator";
import Memoizable from "./Memoizable";

export default Memoizable(class ConfiguratorData {
  conf_id: number;
  
  // Zones management
  zones: Signal<ConfiguratorZone[]>;
  selectedZoneIds = signal([] as number[]);
  zoomedZoneId = signal(undefined as number | undefined);
  selectedZones = computed(() => this.zones.value.filter(z => this.selectedZoneIds.value.includes(z.id)))
  
  categoriePlaceColors: Record<string, HexColorHash> = {}
  
  // Places management
  places = signal([] as ConfiguratorPlace[]);
  selectedPlaceIds = signal([] as number[]);
  selectedPlaces = computed(() => this.places.value.filter(p => this.selectedPlaceIds.value.includes(p.id)))
  
  // for interactive map
  zonesAsInteractiveMapZones = computed(() => this.zones.value.map(z => ({
    ...z,
    fill_color: z.couleur_principale,
    disponible: z.disponibles.some(e => e == "true")
  } as ZoneSVGData)))
  placesAsInteractiveMapPlaces = computed(() => this.places.value.map(p => ({
    ...p,
    fill_color: p.categorie_place_ids.every(c => c == p.categorie_place_ids[0]) ? this.categoriePlaceColors[p.categorie_place_ids[0]] : undefined,
    disponible: p.disponibles.some(e => e == "true")
  } as PlaceSVGData)))
  
  constructor(conf_id: number, initZones: ConfiguratorProps["zones"], categoriePlaces: ConfiguratorProps["categories"]) {
    this.conf_id = conf_id
    this.zones = signal(initZones)
    for (const categoriePlace of categoriePlaces)
      this.categoriePlaceColors[categoriePlace.value] = categoriePlace.couleur;
  }
  
  // This function will reset the zone state
  resetZoneState() {
    if (this.places.value.length) this.places.value = []
    this.zoomedZoneId.value = undefined
  }
  
  // This function will zoom to the zone, and fetch the places of the zone
  async zoomToZone(id: number) {
    this.zoomedZoneId.value = id
    this.places.value = await get(`/dashboard/configuration_stades/${this.conf_id}?zone_logique_ids[]=${id}`)
      .then(r => r.json)
      .then((d: ConfiguratorPlace[]) => d)
    console.log(this.places.value)
  }
  
  // This function will reset the selection
  resetSelection() {
    this.selectedZoneIds.value = []
    this.selectedPlaceIds.value = []
  }
  
  // This function will apply the alterations to the map, from the server response
  applyAlterations({zone_logiques, place_logiques}: {
    zone_logiques: ConfiguratorZone[],
    place_logiques: ConfiguratorPlace[]
  }) {
    this.zones.value = this.zones.value.map(z => {
      const zone = zone_logiques.find(zl => zl.id === z.id)
      return zone ? {...z, ...zone} : z
    })
    this.places.value = this.places.value.map(p => {
      const place = place_logiques.find(pl => pl.id === p.id)
      return place ? {...p, ...place} : p
    })
  }
})