import {Signal, untracked, useComputed, useSignalEffect} from "@preact/signals";
import { MapProps} from "./InteractiveMap";
import {ComponentChildren} from "preact";
import MapController from "../classes/MapController";

type MoveEvent = MouseEvent & { type: "mousemove" | "click" } | TouchEvent & { type: "touchmove" | "touchend" }
type MoveEventWithoutEnd = MouseEvent & { type: "mousemove" } | TouchEvent & { type: "touchmove" }


interface MainSVGProps {
  controller: InstanceType<typeof MapController>;
  config: MapProps["config"];
  zoomedZoneId: Signal<number | undefined>;
  children: ComponentChildren;
  checkCanMove?: (ev: MoveEvent) => boolean;
}

export default function MainSVG({
  controller,
  config,
  zoomedZoneId,
  children,
  checkCanMove
}: MainSVGProps) {
  const svgStyle = useComputed(() => (`
    --scaling: calc( 1 / ${controller.renderScale.value});
    transform: scale(${controller.transform.value.z / controller.renderScale.value}) translate(${controller.transform.value.x}%, ${controller.transform.value.y}%);
    ${controller.movementStates.dragging.value || controller.movementStates.pinchZooming.value ? "transition-property: none;" : ""}
    ${controller.movementStates.wheelZooming.value ? "transition-duration: .1s;" : ""}
  `.replace(/\s+/g, " ")));
  const complexBGStyle = useComputed(() => (
    `display: ${!zoomedZoneId.value ? "none" : undefined}`
  ));
  const simpleBGStyle = useComputed(() => (
    `display: ${zoomedZoneId.value ? "none" : undefined}`
  ));
  
  useSignalEffect(() => {
    determineTransformToFitZone(zoomedZoneId.value)
  })
  
  function determineTransformToFitZone(id?: number, coverFactorY = 0.8, coverFactorX = 0.8) {
    if (id === undefined || controller.ref.current === null) return controller.transform.value = {x: 0, y: 0, z: 1};
    const zone = controller.ref.current.querySelector<SVGPathElement>(`[data-zone-id="${id}"]`)
    if (!zone) return
    const offset = zone.offsetFrom(controller.ref.current.parentElement!)!
    
    const newTrans = untracked(() => ({...controller.transform.value}))
    newTrans.x += (-offset.right - offset.left) / 2 / offset.parent.width * 100 / newTrans.z
    newTrans.y += (-offset.bottom - offset.top) / 2 / offset.parent.height * 100 / newTrans.z
    newTrans.z *= Math.min(
      ((offset.parent.width * coverFactorX) / offset.child.width),
      ((offset.parent.height * coverFactorY) / offset.child.height),
    )
    
    controller.movementStates.zooming.value = true
    return controller.transform.value = newTrans
  }
  
  function cantMove(ev: MoveEvent) {
    return !["touchmove", "mousemove"].includes(ev.type) || checkCanMove?.(ev) === false
  }
  
  function dragToMove(ev: MoveEvent) {
    if (["touchend", "click"].includes(ev.type)) {
      if (controller.movementStates.dragging.value) {
        ev.preventDefault();
        ev.stopPropagation()
      }
      controller.dragMove.value = {lastPos: {x: 0, y: 0}, lastEventPassed: false}
      return controller.movementStates.dragging.value = false
    }
    
    if (ev.type == "touchmove" ? ev.touches.length < 1 : ev.type == "mousemove" ? ev.buttons != 1 : false) return // no touch or no left click
    if (cantMove(ev)) return
    
    //Firefox when clicking on svg, there is a mousemove event with buttons == 1 triggered before the click event, so we
    //we want to ignore the first mousemove event
    if (!controller.dragMove.value.lastEventPassed)
      return controller.dragMove.value = {...controller.dragMove.value, lastEventPassed: true}
    
    const e = ev as MoveEventWithoutEnd
    if (e.type == "touchmove") e.preventDefault() // prevent scrolling
    
    let lastPos = {...controller.dragMove.value.lastPos}
    const {clientX, clientY} = e.type == "touchmove" ? e.touches[0] : e
    
    if (lastPos.x == 0 && lastPos.y == 0) lastPos = {x: clientX, y: clientY}
    
    const movement = {x: clientX - lastPos.x, y: clientY - lastPos.y}
    movement.x /= controller.transform.value.z / controller.renderScale.value
    movement.y /= controller.transform.value.z / controller.renderScale.value
    lastPos = {x: clientX, y: clientY}
    
    const newTrans = {...controller.transform.value}
    // don't forget that the transform is a percentage of the svg width/height
    newTrans.x += movement.x / controller.ref.current.clientWidth * 100
    newTrans.y += movement.y / controller.ref.current.clientHeight * 100
    
    if (newTrans.x != controller.transform.value.x || newTrans.y != controller.transform.value.y)
      controller.movementStates.dragging.value = true
    
    controller.dragMove.value = {...controller.dragMove.value, lastPos}
    controller.transform.value = newTrans
    //onDrag?.(_svgTransform.x, _svgTransform.y)
  }
  
  function alterZoom(delta: number) {
    const newTrans = {...controller.transform.value}
    newTrans.z = Math.max(1, Math.min(20, newTrans.z - delta / 500 * newTrans.z))
    controller.transform.value = newTrans
    //onZoom?.(_svgTransform.z)
  }
  
  function onWheelZoom(e: WheelEvent) {
    if (controller.movementStates.dragging.value || controller.movementStates.zooming.value || controller.movementStates.pinchZooming.value) return
    e.preventDefault();
    e.stopPropagation()
    alterZoom(e.deltaY)
    controller.movementStates.wheelZooming.value = true
  }
  
  function onPinchOrDrag(e: TouchEvent) {
    if (e.touches.length != 2) dragToMove(e as MoveEvent)
    if (e.type == "touchend") {
      controller.movementStates.pinchZooming.value = false;
      controller.movementStates.lastPinchDistance.value = 0
    }
    if (e.type != "touchmove" || controller.movementStates.dragging.value || controller.movementStates.zooming.value || controller.movementStates.wheelZooming.value) return
    e.preventDefault();
    e.stopPropagation()
    if (!controller.tickTrigger) return
    const [{clientX, clientY}, {clientX: clientX2, clientY: clientY2}] = Array.from(e.touches)
    const cpd = Math.sqrt(Math.pow(clientX - clientX2, 2) + Math.pow(clientY - clientY2, 2))
    if (controller.movementStates.lastPinchDistance.value == 0) return controller.movementStates.lastPinchDistance.value = cpd
    alterZoom(-(cpd - controller.movementStates.lastPinchDistance.value) * 5)
    controller.movementStates.pinchZooming.value = true
    controller.movementStates.lastPinchDistance.value = cpd
  }
  
  if (window.debug) console.log("rendered svg")
  return <svg ref={controller.ref} width={config.svg_width / 2} height={config.svg_height / 2}
              viewBox={`0 0 ${config.svg_width} ${config.svg_height}`}
              style={svgStyle}
              onMouseMove={dragToMove as (e: MouseEvent) => void}
              onTouchMove={onPinchOrDrag}
              onTouchEnd={onPinchOrDrag}
              onClick={dragToMove as (e: MouseEvent) => void}
              onWheel={onWheelZoom}
              onTransitionEnd={() => {
                controller.movementStates.zooming.value = false
                controller.movementStates.wheelZooming.value = false
              }}
              onDragStart={e => e.preventDefault()}
              xmlns="http://www.w3.org/2000/svg">
    <g dangerouslySetInnerHTML={{__html: config.static_html.simple_bg}} style={simpleBGStyle}/>
    <g dangerouslySetInnerHTML={{__html: config.static_html.complex_bg}} style={complexBGStyle}/>
    <g dangerouslySetInnerHTML={{__html: config.static_html.partial_defs}}></g>
    {children}
  </svg>
}