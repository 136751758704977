import {signal, Signal} from "@preact/signals";
import {useMemo} from "react";

/**
 * A hook that creates a signal for each key of the object, and returns an object with the signals.
 * Object is memoized to avoid to create a new object at each render.
 * @param {Record<string, any>} inits - the initial values of the signals
 */
export default function useSignals<I = {}>(inits: I): { [K in keyof I]: Signal<I[K]> } {
  const out = {} as { [K in keyof I]: Signal<I[K]> }
  for (const k in inits) {
    out[k] = signal(inits[k])
  }
  return useMemo(() => out, [])
}