import SelectionArea, {Quantify} from "@viselect/vanilla";
import {useEffect, useState} from "react";
import {multiSelectKeyPressed} from "../utils/os_utils";

export default class AreaSelector {
  selectionArea!: SelectionArea;
  _selectables?: Quantify<string>;
  _parent?: HTMLElement;
  noActionOnShift: boolean = false;
  noActionOnCtrl: boolean = false;
  updateCallbacks: ((elements: Element[], added: Element[], removed: Element[]) => void)[] = [];
  _destroyed: boolean = false;
  _disabled = false;
  
  
  constructor({parent, selectableCssSelectors, noActionOnShift, noActionOnCtrl}: {
    parent?: HTMLElement,
    selectableCssSelectors?: Quantify<string>,
    noActionOnShift?: boolean,
    noActionOnCtrl?: boolean,
  }) {
    this._parent = parent;
    this._selectables = selectableCssSelectors;
    this.noActionOnShift = noActionOnShift || false;
    this.noActionOnCtrl = noActionOnCtrl || false;
    this._init();
  }
  
  updateParent(parent?: HTMLElement) {
    this._parent = parent;
    this.selectionArea.destroy();
    this._init();
  }
  
  updateSelectables(selectableCssSelectors?: Quantify<string>) {
    this._selectables = selectableCssSelectors;
    this.selectionArea.destroy();
    this._init();
  }
  
  _init() {
    this.selectionArea = new SelectionArea({
      container: this._parent,
      selectables: this._selectables,
      behaviour: {overlap: "keep"},
      selectionAreaClass: "selection-area",
    });
    
    this.selectionArea.on("beforestart", (e) => {
      if (e.event?.shiftKey && this.noActionOnShift) return false;
      if (multiSelectKeyPressed(e.event) && this.noActionOnCtrl) return false;
      if (!this._parent) return false;
      if (e.event?.target instanceof Element && !e.event?.target.isDescendantOf(this._parent)) return false;
      if (e.event instanceof MouseEvent && ![1].includes(e.event.buttons)) return false;
      if (!multiSelectKeyPressed(e.event)) this.clearSelection();
      if (this._disabled) {
        return false;
      }
    })
    
    this.selectionArea.on("move", (e) => {
      e.store.selected.push(...e.store.stored)
      const selected = [...new Set(e.store.selected)]
      this.updateCallbacks.forEach(cb => cb(selected, e.store.changed.added, e.store.changed.removed))
    })
    
    this.selectionArea.resolveSelectables()
    
    this._destroyed = false;
  }
  
  onUpdate(callback: (elements: Element[], added: Element[], removed: Element[]) => void) {
    this.updateCallbacks.push(callback);
  }
  
  clearSelection() {
    for (const cb of this.updateCallbacks) {
      cb([], [], this.selectionArea.getSelection());
    }
    this.selectionArea.clearSelection(true, true);
  }
  
  destroy() {
    this.clearSelection();
    this.selectionArea.destroy();
    this.updateCallbacks = [];
    this._destroyed = true;
    this._disabled = true;
  }
  
  resume() {
    if (this._destroyed) this._init();
    this.selectionArea.enable()
    this.selectionArea.resolveSelectables()
    this._disabled = false;
  }
  
  pause() {
    this.selectionArea.disable()
    this._disabled = true;
  }
}

export type UseAreaSelectorOptions = {
  parent?: HTMLElement | (() => HTMLElement | undefined),
  selectableCssSelectors?: Quantify<string>,
  noActionOnShift?: boolean,
  noActionOnCtrl?: boolean,
  onUpdate?: (elements: Element[], added: Element[], removed: Element[]) => void
}

export function useAreaSelector({
  parent,
  selectableCssSelectors,
  noActionOnShift,
  noActionOnCtrl,
  onUpdate
}: UseAreaSelectorOptions) {
  const [areaSelector] = useState<AreaSelector>(selector)
  
  function selector() {
    if (window.debug) console.log("selector created")
    const as = new AreaSelector({
      parent: typeof parent === "function" ? parent() : parent,
      selectableCssSelectors,
      noActionOnShift,
      noActionOnCtrl
    })
    onUpdate && as.onUpdate(onUpdate)
    return as
  }
  
  useEffect(() => {
    return () => areaSelector?.destroy(); // cleanup
  }, [])
  
  useEffect(() => {
    areaSelector?.destroy();
    if (onUpdate) areaSelector?.onUpdate(onUpdate);
    areaSelector?.resume();
  }, [onUpdate])
  
  useEffect(() => {
    areaSelector?.updateParent(typeof parent === "function" ? parent() : parent);
  }, [parent])
  
  useEffect(() => {
    areaSelector?.updateSelectables(selectableCssSelectors);
  }, [selectableCssSelectors])
  
  useEffect(() => {
    areaSelector && (areaSelector.noActionOnShift = noActionOnShift ?? false)
  }, [noActionOnShift])
  
  useEffect(() => {
    areaSelector && (areaSelector.noActionOnCtrl = noActionOnCtrl ?? false)
  }, [noActionOnCtrl])
  
  return areaSelector;
}