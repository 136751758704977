import {useEffect} from 'react'
import InteractiveMap, {ActionTarget, AreaSelectionType, SelectionVisual} from "./subs/InteractiveMap"
import {flash} from "./Toast";
import {computed, Signal, useComputed, useSignal, useSignalEffect} from "@preact/signals";
import {useMemoizedPureComponent} from "./hooks/useMemoizedPureComponent";
import ConfiguratorData from "./classes/ConfiguratorData";
import ConfiguratorFormData from "./classes/ConfiguratorFormData";
import TooltipData from './classes/TooltipData';
import {patch} from '../lib/request';
import {multiSelectKeyPressed} from "./utils/os_utils";

export type ConfiguratorProps = {
  config: SVGGlobals,
  couleur_zone_mixe: HexColorHash,
  zones: ConfiguratorZone[],
  disponibles: SelectOption[],
  categories: (SelectOption & { couleur: HexColorHash })[],
  reservoirs: SelectOption[],
  configuration_stade_id: number,
  sideHTML?: string,
}

enum Tools { ZOOM = "zoom", SELECT_AREA = "select-area", SELECT_LINE = "select-line" }

const toolInfos = {
  [Tools.ZOOM]: "Cet outil permet de zoomer dans les différentes zones de la carte, si vous êtes zoomé, cliquer sur une place n'aura aucun effet.",
  [Tools.SELECT_AREA]: "Cet outil permet de séléctionner un ou plurieurs éléments à la fois. Si la carte est zoomée sur une zone, la sélection ne pourra être faite que sur des places, et inversement.",
  [Tools.SELECT_LINE]: "Cet outil est purement pratique pour sélectionner la ligne de la place cliquée lorsque vous êtes zoomé",
}

export default function Configurator({
  config,
  zones: _zones,
  couleur_zone_mixe,
  configuration_stade_id,
  disponibles,
  categories,
  reservoirs,
  sideHTML = "",
}: ConfiguratorProps) {
  // Tools that will change the behavior of the map
  const tool = useSignal<Tools>(Tools.ZOOM)
  const areaSelectionType = useComputed(() => tool.value === Tools.SELECT_AREA ? AreaSelectionType.ZONES_OR_PLACES : AreaSelectionType.NONE)
  
  // Data that will be altered and used by the map
  const mapData = ConfiguratorData.memo([configuration_stade_id, _zones, categories])
  
  // Data that will be altered and used by the form, and sent to the server
  const formData = ConfiguratorFormData.memo([mapData, couleur_zone_mixe, {
    disponibles,
    categories,
    reservoirs,
  }])
  
  // Data to control the tooltip (small infos shown when hovering an element)
  const tooltipData = TooltipData.memo([])
  
  // on first render, we set the tooltip instance, if containerRef is set
  useEffect(() => tooltipData.init(), [])
  
  // when any dependants from selection changes, we alter the form data
  useSignalEffect(() => formData.alterFromSelection())
  
  // will show tooltip on hover, and hide it on leave
  function onHover(actionTarget: ActionTarget, el: SVGElement, id: number, out: boolean) {
    if (out) tooltipData.hide()
    else if (actionTarget === ActionTarget.PLACE) {
      const place = mapData.places.value.find(p => p.id === id)
      if (place) tooltipData.show(el, `Rang ${place.rang} - Siège ${place.numero_place}`)
    }
  }
  
  // when a zone is clicked, we reset the selection, and zoom to the zone if the tool is set to zoom, and id is set
  // if no id is set, we reset the selection and the zone state, that will normally reset the map position
  // when a place is clicked, and line selection tool is set, we select all the places with the same rang
  async function onClick(actionTarget: ActionTarget, _: SVGElement, id: number, ev: MouseEvent) {
    if (actionTarget === ActionTarget.ZONE) {
      if (tool.value == Tools.ZOOM) await mapData.zoomToZone(id)
    } else if (actionTarget === ActionTarget.PLACE) {
      if (tool.value === Tools.SELECT_LINE) {
        const placeRang = mapData.places.value.find(p => p.id === id)?.rang
        const curr_pls = multiSelectKeyPressed(ev) ? [...mapData.selectedPlaceIds.value] : []
        const toselect_pls = mapData.places.value.filter(p => p.rang === placeRang).map(p => p.id)
        if (curr_pls.includes(id)) {
          mapData.selectedPlaceIds.value = curr_pls.filter(p => !toselect_pls.includes(p))
        } else mapData.selectedPlaceIds.value = [...curr_pls, ...toselect_pls]
      }
    }
  }
  
  // when the form is submitted, we send the data to the server, and apply the changes to the map
  async function send(e: MouseEvent) {
    if (!(e.target instanceof HTMLButtonElement)) return;
    e.target.ariaBusy = 'true'
    await patch(`/dashboard/configuration_stades/${configuration_stade_id}`, {
      body: JSON.stringify({...formData.dataToSend})
    })
      .then(r => r.json)
      .then((data: Parameters<typeof mapData.applyAlterations>[0]) => {
        mapData.applyAlterations(data)
        flash("Configuration mise à jour")
      })
    e.target.ariaBusy = 'false'
  }
  
  useSignalEffect(() => {
    tool.value // to trigger the effect when the tool changes
    mapData.selectedPlaceIds.value = []
    mapData.selectedZoneIds.value = []
  })
  
  const ToolsSelector = useMemoizedPureComponent((
    {tool}: { tool: Signal<Tools> }
  ) => {
    const data = Object.values(Tools).map(t => ({
      tool: t,
      class: computed(() =>
        (tool.value === t ? "btn-surface text-info" : "btn-background") +
        " !p-2xs" +
        (t == Tools.SELECT_LINE && !mapData.zoomedZoneId.value ? " hidden" : "")
      ),
      info: toolInfos[t],
    }))
    
    if (window.debug) console.log("rendered tool selector")
    return <> {data.map(t =>
      <button key={t.tool} className={t.class} onClick={() => tool.value = t.tool} title={t.info}>
        <i className={`icon-${t.tool}`}></i>
      </button>
    )} </>
  })
  
  const Form = useMemoizedPureComponent(() => {
    const keys = Object.keys(formData.data) as (keyof typeof formData.data)[]
    const selectionText = computed(() =>
      mapData.zoomedZoneId.value
        ? `${mapData.selectedPlaceIds.value.length} places séléctionnées`
        : `${mapData.selectedZoneIds.value.length} zones séléctionnées`
    )
    
    if (window.debug) console.log("rendered form")
    return <>
      <div class="flex justify-between">
        <b>Editer la séléction</b>
        {selectionText}
      </div>
      {keys.map(key => {
        const d = formData.data[key]
        return <div key={key} className={d.class}>
          <div className="field-container">
            <div className="icon flex items-center justify-center"></div>
            <select id={d.id}
                    value={d.value}
                    onChange={({target}) => d.value.value = (target as HTMLSelectElement).value as any}
            >
              {d.opts}
            </select>
          </div>
          <label htmlFor={d.id}>{d.label}</label>
        </div>
      })}
    </>
  })
  
  if (window.debug) console.log("rendered configurator")
  return <>
    <div className={"bg-background border-b tab use-as-links w-full hidden"}>
      <div className="tab-tabs overflow-auto w-full whitespace-nowrap px-lg pt-2xs">
      </div>
    </div>
    <div className="flex-1 relative">
      <div className="absolute inset-0 flex">
        <div className="bg-background border-r w-[36rem] p-lg">
          <div>Outils</div>
          <div className="card bg-surface inline-flex gap-2xs !p-2xs mt-2xs">
            <ToolsSelector {...{tool}}/>
          </div>
        </div>
        <section id="configurateur">
          <InteractiveMap
            zoomedZoneId={mapData.zoomedZoneId}
            config={config}
            zones={mapData.zonesAsInteractiveMapZones}
            places={mapData.placesAsInteractiveMapPlaces}
            onHover={onHover}
            selectedPlaceIds={mapData.selectedPlaceIds}
            selectedZoneIds={mapData.selectedZoneIds}
            onClick={onClick}
            onReset={() => {
              mapData.resetZoneState()
              tool.value = Tools.ZOOM
            }}
            selectionVisual={SelectionVisual.OVERLAY}
            areaSelectionType={areaSelectionType}
            bypassDisponibility={true}
          />
          <div ref={tooltipData.containerRef} className="tooltip"/>
        </section>
        <div className="bg-background p-lg w-[44rem] flex flex-col">
          <div dangerouslySetInnerHTML={{__html: sideHTML}}/>
          <div className="grid gap-xs">
            <Form/>
          </div>
          <div className="flex-1"></div>
          <button name="button" onClick={send}>{window.I18n.t("save")}</button>
        </div>
      </div>
    </div>
  </>
}
