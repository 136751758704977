import {useEffect} from "react";
import InteractiveMap, {ActionTarget} from "./subs/InteractiveMap";
import {get} from "../lib/request";
import {flash} from "./Toast";
import InteractiveStadiumData from "./classes/InteractiveStadiumData";
import TooltipData from "./classes/TooltipData";
import InteractiveStadiumPlacesList from "./classes/InteractiveStadiumPlacesList";
import {useMemoizedPureComponent} from "./hooks/useMemoizedPureComponent";
import {getTarifsForCategoriePlace} from "./utils/tarif";

type InteractiveStadiumProps = {
  config: SVGGlobals,
  // If limite place == 0, no limit otherwise has limit
  limite_place: number,
  filters_enabled: boolean,
  modele_article: ModeleArticle,
  categorie_places: CategoriePlace[],
  zones: InteractiveStadiumZone[],
  tarifs: Tarif[],
  user_key: string,
  for_stockprint?: boolean,
}

export default function InteractiveStadium({
  config,
  limite_place,
  filters_enabled,
  modele_article,
  zones,
  tarifs,
  categorie_places,
  user_key,
  for_stockprint
}: InteractiveStadiumProps) {
  const mapData = InteractiveStadiumData.memo([zones])
  const tooltipData = TooltipData.memo([])
  const placesList = InteractiveStadiumPlacesList.memo([for_stockprint, user_key, limite_place, tarifs])
  
  useEffect(() => tooltipData.init(), [])
  
  async function onClick(actionTarget: ActionTarget, _: Element, id: number) {
    if (actionTarget === ActionTarget.ZONE) {
      mapData.zoomedZoneId.value = id
      const zoneData: ZoneDataWithPlaces = await get(`/modele_articles/${modele_article.id}/stade_interactif/${id}/place_logiques`)
        .then(res => res.json)
      
      mapData.places.value = zoneData.place_logiques
      delete (zoneData as Omit<ZoneDataWithPlaces, "place_logiques"> & { place_logiques: any }).place_logiques
      mapData.zoomedZoneData = zoneData
    } else if (actionTarget === ActionTarget.PLACE) {
      if (placesList.includes(id)) placesList.remove(id)
      else placesList.add({
        zoneData: mapData.zoomedZoneData!,
        placeLogique: mapData.places.value.find(p => p.id === id)!
      })
    }
  }
  
  function onHover(actionTarget: ActionTarget, el: SVGElement, id: number, out: boolean) {
    if (out) tooltipData.hide()
    else if (actionTarget === ActionTarget.PLACE) {
      if (!mapData.zoomedZoneData) return

      const place = mapData.places.value.find(p => p.id === id)
      if (!place) return

      const categPlace = categorie_places.find(c => c.id === place!.categorie_place_id)
      if (!categPlace) return

      const placeTarifs = getTarifsForCategoriePlace(tarifs, categPlace!.id)
      if (!placeTarifs || placeTarifs.length === 0) return

      const tarif = placeTarifs[0]

      tooltipData.show(el, `
        <div class="header rounded-t-md" style="height: .4rem; background-color: ${categPlace.couleur}"></div>
        <div class="flex items-baseline gap-2xs -mt-3xs">
          <div class="h-xs w-xs translate-y-4xs scale-90 rounded-full" style="background-color: ${categPlace.couleur}"></div>
          ${categPlace.nom}
        </div>
        <b>
          Rang ${place.rang} - Siège ${place.numero_place}
        </b> <br>
        Travée ${place.travee}
        <hr class="my-2xs">
        <div class="flex justify-between items-center gap-sm">
         ${tarif.type_tarif}
         <b>${(tarif.prix_ttc.cents || 0) / 100} €</b>
        </div>
      `)
    }
  }
  
  function sendList(e: MouseEvent) {
    if (!(e.target instanceof HTMLButtonElement)) return
    if (e.target.ariaBusy === "true") return
    const articles_attributes = placesList.data.value.map((place) => ({
      place_logique_id: place.placeLogique.id,
      tarif_id: place.selected_tarif_id.value ?? tarifs[0].id,
    }))
    
    const body = for_stockprint
      ? {impression_stock: {articles_attributes}}
      : {panier: {modele_article_id: modele_article.id, articles_attributes}}
    
    e.target.classList.add("disabled")
    e.target.ariaBusy = "true"
    
    const url = for_stockprint ? '/dashboard/impression_stocks/finalize' : '/panier'
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.getAttribute("content") ?? ""
      },
      body: JSON.stringify(body)
    }).then(res => {
      if (res.status === 200) {
        localStorage.removeItem('local-cart')
        setTimeout(() => {
          window.Turbo.visit(url)
        })
      } else if (res.status === 201) {
        localStorage.removeItem('places-list');
        let location_url = res.headers.get('location');
        if (location_url) setTimeout(() => window.Turbo.visit(location_url!));
      } else {
        (e.target as HTMLButtonElement).ariaBusy = "false";
        flash("An error occured", "error")
      }
    });
  }
  
  const PlaceList = useMemoizedPureComponent((
    {placesList: _placesList}: { placesList: typeof placesList }
  ) => {
    const placesList = _placesList.data.value
    const remove = (id: number) => _placesList.remove(id)
    
    return placesList.length == 0
      ? <div id="empty-cart-indicator" className="absolute inset-0 overflow-hidden pointer-events-auto bg-surface">
        <div
          className="h-full w-full flex flex-col justify-center items-center text-center">
          <div
            className="h-3xl w-3xl border bg-background flex items-center justify-center text-h1 rounded-full scale-90">
            O
          </div>
          <div className="text-h3 text-on-background">Aucune place sélectionnée</div>
          Sélectionnez vos places sur le plan
        </div>
      </div>
      : <div id="cart-items"
             className="flex flex-col overflow-auto bg-surface absolute inset-0 gap-sm p-sm lg:pt-xl pointer-events-auto">
        {placesList.map(({placeLogique, selected_tarif_id, price}) => {
          const categPlace = categorie_places.find(c => c.id === placeLogique.categorie_place_id)!
          const placeTarifs = getTarifsForCategoriePlace(tarifs, placeLogique.categorie_place_id)
          
          return <div key={placeLogique.id} className="card overflow-visible p-sm">
            <div className="flex justify-between">
              <div className="flex items-baseline gap-2xs">
                <div className="h-xs w-xs translate-y-4xs scale-90 rounded-full"
                     style={{backgroundColor: categPlace.couleur}}/>
                {categPlace.nom}
              </div>
              <div className="p-4 icon-delete cursor-pointer text-error brightness-90 -mt-xs -mr-xs"
                   onClick={() => remove(placeLogique.id)}/>
            </div>
            <b className="my-3xs block">Rang {placeLogique.rang} - Siège {placeLogique.numero_place}</b>
            <div>Travée: {placeLogique.travee}</div>
            <hr className="mb-xs mt-2xs"/>
            <div className="flex justify-between items-center gap-md">
              <div className="field flex-1">
                <select className="tarif !py-2xs !px-2xs" title="Tarif de la place"
                        value={selected_tarif_id}
                        onChange={({target}) => selected_tarif_id.value = (target as HTMLSelectElement).value}>
                  {placeTarifs.map(tarif =>
                    <option key={tarif.id}
                            value={tarif.id}> {tarif.type_tarif} - {tarif.prix_ttc.cents / 100} €</option>
                  )}
                </select>
              </div>
              <b>{price} €</b>
            </div>
          </div>
        })}
      </div>
  })
  
  return <>
    <div className="flex-1 relative">
      <div className="absolute inset-0 lg:bottom-3xl">
        <section id="map-container">
          <InteractiveMap
            config={config}
            zones={mapData.zones}
            places={mapData.places}
            zoomedZoneId={mapData.zoomedZoneId}
            onClick={onClick}
            onHover={onHover}
            selectedPlaceIds={placesList.selectedPlaceIds}
            onReset={() => mapData.resetZoneState()}
            // onDrag={() => tooltipData.hide()}
            // onZoom={() => tooltipData.hide()}
          />
          <div ref={tooltipData.containerRef} className="tooltip"/>
        </section>
      </div>
    </div>
    <aside className="bg-surface flex flex-col">
      <div className="p-lg border-b bg-background grid grid-cols-2 gap-xs lg:hidden">
        {categorie_places
            .filter(categorie_place => categorie_place.visible)
            .map(categorie_place => <div key={categorie_place.id}
                                                      className="flex items-baseline gap-2xs">
          {
            !categorie_place.couleur
                ? <div className="h-xs w-xs translate-y-4xs scale-90 rounded-full bg-border"></div>
                : <div className="h-xs w-xs translate-y-4xs scale-90 rounded-full"
                       style={{backgroundColor: categorie_place.couleur}}></div>
          }
          {categorie_place.nom}
        </div>)}
      </div>
      <div className="flex flex-1 flex-col pointer-events-none lg:absolute lg:inset-0 z-0">
        <div className="flex-1 relative z-10 flex flex-col justify-end">
          <div className="hidden lg:block pointer-events-auto z-10 relative h-0">
            <button type="button"
                    className="rotate-180 btn-background absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 border-border rounded-full p-2xs"
                    onClick={(e) => {
                      (e.target as HTMLButtonElement).parentElement?.nextElementSibling?.slideToggle(150).then(() => {
                        (e.target as HTMLButtonElement).classList.toggle('rotate-180');
                      });
                    }}
            >
              <i className="icon-angle-down !text-h2"></i>
            </button>
          </div>
          <div className="z-0 relative h-full lg:opacity-0 lg:max-h-0">
            <PlaceList placesList={placesList}/>
          </div>
          <div
            className="p-lg sm:p-sm pt-sm border-t bg-background flex flex-col gap-sm pointer-events-auto z-0 relative">
            <div className="flex justify-between">
              <div>
                {window.I18n.t("tickets")} :
                <span id="cart-items-count"> {placesList.length} {limite_place > 0 && `(Max: ${limite_place})`}</span>
              </div>
              <b><span id="total-price">{placesList.totalPrice}</span> €</b>
            </div>
            <p className="text-primary empty:hidden">{placesList.limitReachedMessage}</p>
            <button type="submit" className="px-3xl" onClick={sendList} disabled={placesList.limitReached}>
              {window.I18n.t(for_stockprint ? "end_selection" : "add_to_cart")}
            </button>
          </div>
        </div>
      </div>
    </aside>
  </>
}