import {MapProps} from "./InteractiveMap";
import {Signal, useComputed, useSignal} from "@preact/signals";
import {useCallback, useEffect, useRef} from "react";

interface MainSVGProps {
  notTouched: Signal<boolean>;
  config: MapProps["config"];
  zoomedZoneId: Signal<number | undefined>;
  onReturn: () => void;
  zones: MapProps["zones"];
}

export default function MapUI({notTouched, config, zoomedZoneId, onReturn, zones}: MainSVGProps) {
  const returnBtnClass = useComputed(() => `btn-primary${notTouched.value ? " hidden" : ""}`)
  const isHovered = useSignal(false);
  const svgClass = useComputed(() => "map" + (isHovered.value ? " hover" : ""))
  const svgUiRef = useRef<SVGSVGElement>(undefined as any)
  
  const svgUiHover = useCallback((e: MouseEvent | TouchEvent) => {
    const {clientX, clientY} = e.type.startsWith("touch") ? (e as TouchEvent).touches[0] : (e as MouseEvent);
    const {left, right, top, bottom} = svgUiRef.current.getBoundingClientRect()
    if (clientX >= left && clientX <= right && clientY >= top && clientY <= bottom) {
      if (!isHovered.value)
        isHovered.value = true
    } else {
      if (isHovered.value)
        isHovered.value = false
    }
  }, [])
  
  useEffect(() => {
    document.addEventListener("mousemove", svgUiHover)
    document.addEventListener("touchmove", svgUiHover)
    return () => {
      document.removeEventListener("mousemove", svgUiHover)
      document.removeEventListener("touchmove", svgUiHover)
    }
  })
  
  if (window.debug) console.log("rendered map ui")
  return <div className="ui">
    <button className={returnBtnClass} onClick={onReturn}>{window.I18n.t("back")}</button>
    <svg ref={svgUiRef} className={svgClass} width={config.svg_width}
         height={config.svg_height}
         viewBox={`0 0 ${config.svg_width} ${config.svg_height}`}>
      <g dangerouslySetInnerHTML={{__html: config.static_html.simple_bg}}></g>
      {zones.value.map((zone) =>
        <path
          key={zone.id}
          id={`mini-zone-${zone.id}`}
          d={zone.svg_path}
          data-zone-id={zone.id}
          style={zone.disponible ? {fill: zone.fill_color} : undefined}
          className={"fill-on-background dark-grayscale"}
        />
      )}
      {/* This is a hack to make the selected zone appear on top of the minimap */}
      <DetailedZone zones={zones} zoomedZoneId={zoomedZoneId}/>
    </svg>
  </div>
}

function DetailedZone({zones, zoomedZoneId}: { zones: MapProps["zones"], zoomedZoneId: Signal<number | undefined> }) {
  const detailedZone = useComputed(() => zones.value.find((z) => z.id === zoomedZoneId.value))
  const zone = detailedZone.value
  
  if (window.debug) console.log("rendered map ui detailed zone")
  if (!zone) return null
  return <path
    id={`mini-zone-${zone.id}`}
    d={zone.svg_path}
    data-zone-id={zone.id}
    style={zone.disponible ? {fill: zone.fill_color} : undefined}
    className={"fill-on-background dark-grayscale selected"}
  />
}