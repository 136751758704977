import {useMemo} from "react";

type MemoizableClass<T extends new (...args: any) => any> = T &
  {
    memo(constructorArgs: ConstructorParameters<T>, ifNo?: InstanceType<T> | MemoizableClass<T>): InstanceType<T>
  }

export default function Memoizable<T extends new (...args: any) => any>(
  constructor: T
) {
  const Memoizable = constructor as any as MemoizableClass<T>
  Memoizable.memo = function (constructorArgs: ConstructorParameters<T>, ifNo?: InstanceType<T>) {
    // @ts-ignore
    return useMemo(() => ifNo ?? new this(...constructorArgs) as InstanceType<T>, [])
  }
  return Memoizable
}