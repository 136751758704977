import {ReadonlySignal, useSignalEffect} from "@preact/signals";
type ReadonlySignalOr<T> = ReadonlySignal<T> | T

export function selectOptionsFrom(opts: ReadonlySignalOr<SelectOption[]>, placeholder: ReadonlySignal<string>) {
  return <SelectOptions opts={opts} placeholder={placeholder} />
}

export default function SelectOptions({opts, placeholder}: {
  opts: ReadonlySignalOr<SelectOption[]>,
  placeholder: ReadonlySignal<string>,
}) {
  const _opts = Array.isArray(opts) ? opts : opts.value

  return (<>
    <option value="">{placeholder}</option>
    {_opts.map(o => <option key={o.value} value={o.value} selected={o.selected}>{o.label}</option>)}
  </>)
}