import {useEffect, useState} from "react";
import {Signal, useComputed, useSignal} from "@preact/signals";
import {AreaSelectionType, MapProps} from "./InteractiveMap";
import MapEasteregg from "../classes/MapEasteregg";

interface MapZoneProps {
  id: number;
  path: string;
  fill_color?: HexColorHash;
  zoomedZoneId: Signal<number | undefined>;
  selectedIds: Signal<number[]>;
  svgTransform: Signal<{ x: number, y: number, z: number }>;
  onClick: (el: SVGPathElement, ev: MouseEvent) => Promise<void>;
  onHover?: (el: SVGPathElement, leave: boolean, ev: MouseEvent) => void;
  areaSelectionType: Exclude<MapProps["areaSelectionType"], undefined>;
  disponible?: boolean;
  mapEasteregg: InstanceType<typeof MapEasteregg>
}

export default function MapZone({
  id,
  path,
  fill_color,
  zoomedZoneId,
  selectedIds,
  onClick,
  svgTransform,
  areaSelectionType,
  onHover,
  disponible,
  mapEasteregg
}: MapZoneProps) {
  const [hovered, setHovered] = useState(false);
  const selected = useComputed(() => selectedIds.value.includes(id));
  const loading = useSignal(false);
  const detailed = useComputed(() => zoomedZoneId.value === id)
  const classes = useComputed(() =>
    `dark-grayscale !cursor-default${detailed.value ? " selected" : ""}${loading.value ? " !cursor-progress" : ""}` +
    (
      areaSelectionType.value == AreaSelectionType.ZONES_AND_PLACES ||
      areaSelectionType.value == AreaSelectionType.ZONES_OR_PLACES && !zoomedZoneId.value
        ? " selectable" : ""
    )
  )
  
  async function onClickZone(el: SVGPathElement, ev: MouseEvent) {
    loading.value = true;
    await onClick(el, ev);
    loading.value = false;
  }
  
  const _fill_color = useSignal<HexColorHash | undefined>(fill_color)
  useEffect(() => _fill_color.value = disponible ? fill_color : undefined, [fill_color, disponible])
  
  
  const fill = useComputed(() => mapEasteregg.randomColorOr(_fill_color.value))
  
  
  if (window.debug) console.log("rendered map zone")
  return <>
    {/* Placed behind the place because we want only to display the border */}
    {selected.value && <>
      <path d={path} className={"pointer-events-none !stroke-info"}
            style={{filter: "none", strokeWidth: 40 / svgTransform.value.z}}/>
      <path d={path} className={"pointer-events-none !stroke-background"}
            style={{filter: "none", strokeWidth: 20 / svgTransform.value.z}}/>
    </>}
    
    <path d={path}
          data-zone-id={id}
          fill={fill}
          className={classes}
          onClick={(e) => onClickZone(e.target as SVGPathElement, e)}
          onMouseEnter={(e) => {
            setHovered(true)
            onHover?.(e.target as SVGPathElement, false, e)
          }}
          onMouseLeave={(e) => {
            setHovered(false)
            onHover?.(e.target as SVGPathElement, true, e)
          }}
    />
    {hovered && <path d={path} className={"pointer-events-none fill-info fill-opacity-20 !stroke-info"}
                      style={{filter: "none", strokeWidth: 8 / svgTransform.value.z}}/>}
  </>
}