export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  
  const debounced = (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
    return () => timeout ? clearTimeout(timeout) : void 0;
  };
  
  return debounced;
};

const throttle = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let curr: number
  
  return (...args: Parameters<F>): ReturnType<F> | void => {
    const now = performance.now()
    if (!curr || now - curr >= waitFor) {
      curr = now
      return func(...args)
    }
  }
}