import {useCallback, useEffect, useState} from "react";
import {useAreaSelector, UseAreaSelectorOptions} from "../hooks/useAreaSelector";
import MapZone from "./MapZone";
import {ReadonlySignal, Signal, signal, useSignalEffect} from "@preact/signals";
import MainSVG from "./MainSVG";
import MapUI from "./MapUI";
import MapPlace from "./MapPlace";
import useSignals from "../hooks/useSignals";
import MapController from "../classes/MapController";
import {useMemoizedPureComponent} from "../hooks/useMemoizedPureComponent";
import mapEasteregg from "../classes/MapEasteregg";
import MapEasteregg from "../classes/MapEasteregg";

export enum AreaSelectionType { NONE, ZONES_OR_PLACES, ZONES_AND_PLACES }

export enum ActionTarget { ZONE, PLACE }

export enum SelectionVisual { OVERLAY = "overlay", CLASS_NAME = "class-name" }

export type MapProps = {
  controller?: typeof MapController;
  config: SVGGlobals
  zones: ReadonlySignal<ZoneSVGData[]>
  places: ReadonlySignal<PlaceSVGData[]>
  zoomedZoneId: Signal<number | undefined>,
  areaSelectionType?: Signal<AreaSelectionType>,
  selectedPlaceIds?: Signal<number[]> | ReadonlySignal<number[]>,
  selectedZoneIds?: Signal<number[]> | ReadonlySignal<number[]>,
  onClick?: (actionTarget: ActionTarget, el: SVGElement, id: number, ev: MouseEvent) => void,
  onHover?: (actionTarget: ActionTarget, el: SVGElement, id: number, out: boolean, ev: MouseEvent) => void,
  onReset?: () => void,
  selectionVisual?: SelectionVisual
  bypassDisponibility?: boolean,
}

export default function InteractiveMap({
  controller,
  config,
  zones,
  places,
  zoomedZoneId,
  areaSelectionType = signal(AreaSelectionType.NONE),
  selectedPlaceIds = signal([]),
  selectedZoneIds = signal([]),
  onClick,
  onHover,
  onReset,
  selectionVisual = SelectionVisual.CLASS_NAME,
  bypassDisponibility = false
}: MapProps) {
  const mapController = MapController.memo([], controller)
  const mapStates = useSignals({isDragging: false, isZooming: false, isWheelZooming: false, isPinchZooming: false})
  const mapEasteregg = MapEasteregg.memo([])
  
  useSignalEffect(() => {
    if (mapController.ref.current) {
      if (mapEasteregg.pixelize.enabled.value) mapController.ref.current.parentElement?.parentElement?.classList.add("pixelize-easteregg")
      else mapController.ref.current.parentElement?.parentElement?.classList.remove("pixelize-easteregg")
    }
  })
  useEffect(() => () => mapEasteregg.cleanup(), [])
  
  const updateSelectedIds = useCallback((type: "zone" | "place", els: Element[]) => {
    const signal = type === "zone" ? selectedZoneIds : selectedPlaceIds
    try {
      (signal as Signal<number[]>).value = els
        .map(el => parseInt(el.getAttribute(`data-${type}-id`) ?? ""))
        .filter(id => !isNaN(id))
    } catch (_) { /* it's a readonly signal */
    }
  }, [])
  
  const [useAreaSelectorOptions, _] = useState<UseAreaSelectorOptions>({
    parent: () => mapController.ref.current?.parentElement?.parentElement ?? undefined,
    selectableCssSelectors: [`[data-zone-id].selectable`, `[data-place-id].selectable`],
    noActionOnShift: true,
    onUpdate: (selected, added, removed) => {
      const typesUpdated = new Set<"zone" | "place">()
      for (const el of [...added, ...removed]) {
        if (el.hasAttribute("data-zone-id")) typesUpdated.add("zone")
        if (el.hasAttribute("data-place-id")) typesUpdated.add("place")
      }
      for (const type of typesUpdated) updateSelectedIds(type, selected)
    }
  })
  const selector = useAreaSelector(useAreaSelectorOptions)
  
  useSignalEffect(() => {
    zoomedZoneId.value // track zoomedZoneId
    if (areaSelectionType.value === AreaSelectionType.NONE || mapStates.isDragging.value) {
      selector.clearSelection()
      selector.pause()
    } else {
      selector.resume()
    }
  })
  
  useSignalEffect(() => {
    areaSelectionType.value // track areaSelectionType
    try {
      (selectedZoneIds as Signal<number[]>).value = []
    } catch (_) { /* it's a readonly signal */
    }
    try {
      (selectedPlaceIds as Signal<number[]>).value = []
    } catch (_) { /* it's a readonly signal */
    }
  })
  
  function canClick() {
    return Object.values(mapStates).every(state => !state.value)
  }
  
  const Places = useMemoizedPureComponent((
    {places}: { places: Signal<PlaceSVGData[]> }
  ) => {
    if (window.debug) console.log("rendered places")
    
    return <>
      {places.value.map(place => <MapPlace
        key={place.id}
        zoomedZoneId={zoomedZoneId}
        place={place}
        selectedIds={selectedPlaceIds}
        svgTransform={mapController.transform}
        onClick={(el, ev) => {
          if (!(place.disponible || bypassDisponibility)) return;
          canClick() && onClick?.(ActionTarget.PLACE, el, place.id, ev)
        }}
        selectionVisual={selectionVisual}
        areaSelectionType={areaSelectionType}
        onHover={(el, leave, ev) => onHover?.(ActionTarget.PLACE, el, place.id, leave, ev)}
        mapEasteregg={mapEasteregg}
      />)}
    </>
  })
  
  const Zones = useMemoizedPureComponent((
    {zones}: { zones: Signal<ZoneSVGData[]> }
  ) => {
    
    if (window.debug) console.log("rendered zones")
    return <>
      {zones.value.map(zone => <MapZone
        key={zone.id}
        id={zone.id}
        path={zone.svg_path}
        fill_color={zone.fill_color}
        zoomedZoneId={zoomedZoneId}
        selectedIds={selectedZoneIds}
        svgTransform={mapController.transform}
        onClick={async (el, ev) => {
          if (!(zone.disponible || bypassDisponibility)) return;
          canClick() && onClick?.(ActionTarget.ZONE, el, zone.id, ev)
        }}
        areaSelectionType={areaSelectionType}
        onHover={(el, leave, ev) => onHover?.(ActionTarget.ZONE, el, zone.id, leave, ev)}
        disponible={zone.disponible}
        mapEasteregg={mapEasteregg}
      />)}
    </>
  })
  
  if (window.debug) console.log("rendered map")
  return <div>
    {mapEasteregg.pixelize.enabled.value && <div dangerouslySetInnerHTML={{__html: mapEasteregg.pixelize.svgFilter}}/>}
    
    <MainSVG {...{
      controller: mapController,
      checkCanMove: (ev) => areaSelectionType.value == AreaSelectionType.NONE || ev.shiftKey,
      zoomedZoneId,
      config,
    }}>
      <Places places={places}/>
      <Zones zones={zones}/>
    </MainSVG>
    
    <MapUI
      notTouched={mapController.isAtInitialPosition}
      config={config}
      zoomedZoneId={zoomedZoneId}
      onReturn={() => {
        onReset?.()
        mapController.reset()
      }}
      zones={zones}
    />
  </div>
}