import {useEffect, useRef, useState} from "react";
import {createRoot, Root} from "react-dom/client";

type ToastProps = { title: string, message: string, timeout?: number, align: string, type: string, __root__?: Root }

export function flash(message: string, type = "info", title = 'Information', align = 'right', timeout = 3000) {
  const el = document.querySelector('#toasts')!.insertAdjacentElement('beforeend', document.createElement("div"))!
  el.setAttribute("data-turbo-temporary", "")
  const rt = createRoot(el);
  // @ts-ignore types are good, because we are using preact
  rt.render(<Toast message={message} type={type} title={title} align={align} timeout={timeout} __root__={rt}/>)
}

export default function Toast(this: any, {title, message, timeout, align, type, __root__}: ToastProps) {
  const [open, setOpen] = useState(true);
  const [animateOpen, setAnimateOpen] = useState(true);
  const rootRef = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    setTimeout(() => setOpen(false), timeout ?? 3000)
  }, [])
  
  function handleAnimationend() {
    if (open) setAnimateOpen(false);
    else {
      const container = rootRef.current!.parentElement!;
      if (__root__) __root__.unmount();
      else if (rootRef.current!.parentElement!.__root__) rootRef.current!.parentElement!.__root__.unmount();
      else rootRef.current!.parentElement!.remove();
      if (container) container.remove();
    }
  }
  
  return (
    <div ref={rootRef}
         className={`toast ${type} ${open ? "open" : ""} ${animateOpen ? "animate-open" : ""} toast-${align}`}
         onAnimationEnd={handleAnimationend} onTransitionEnd={handleAnimationend}>
      <div className="toast-icon" onClick={() => setOpen(false)}></div>
      <div className="toast-content">
        <span>{title}</span>
        <p>{message}</p>
      </div>
      <button type="button" className="icon-close" onClick={() => setOpen(false)}></button>
    </div>)
}