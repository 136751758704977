import {effect, signal} from "@preact/signals";
import Memoizable from "./Memoizable";

export default Memoizable(class MapEasteregg {
  rainbow = {enabled: signal(false), timeout: undefined as NodeJS.Timeout | undefined, tick: signal(0)};
  pixelize = {
    enabled: signal(false),
    svgFilter: `
      <style>
        .pixelize-easteregg{
          filter: url(#pixelate);
        }
      </style>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="0" height="0">
        <defs>
          <filter id="pixelate" x="0" y="0">
            <feFlood x="2" y="2" height="1" width="1"/>
            <feComposite width="5" height="5"/>
            <feTile result="a"/>
            <feComposite in="SourceGraphic" in2="a"
                         operator="in"/>
            <feMorphology operator="dilate"
                          radius="2.5"/>
          </filter>
        </defs>
      </svg>
    `
  };
  
  
  typeHistory = "";
  codes = {
    "rainbowrace": this.rainbow,
    "rainbowroad": this.rainbow,
    "zumba": this.rainbow,
    "minecraft": this.pixelize,
    "apple": this.pixelize,
  }
  onTypeBound = this.onType.bind(this);
  
  onType(e: KeyboardEvent) {
    if (e.target !== document.body) return;
    this.typeHistory += e.key;
    console.log(this.typeHistory, e.key);
    const startWithAny = Object.keys(this.codes).some(k => k.startsWith(this.typeHistory));
    if (!startWithAny) this.typeHistory = "";
    const codeTyped = (Object.keys(this.codes) as (keyof typeof this.codes)[]).find(k => this.typeHistory == k);
    if (codeTyped) {
      this.codes[codeTyped].enabled.value = !this.codes[codeTyped].enabled.value;
      this.typeHistory = "";
    }
  }
  
  constructor() {
    effect(() => {
      if (this.rainbow.enabled.value) {
        this.rainbow.tick.value = 1 // will be truethy
        this.rainbow.timeout = setInterval(() => this.rainbow.tick.value++, 500)
      } else if (this.rainbow.timeout) {
        clearInterval(this.rainbow.timeout)
        this.rainbow.tick.value = 0 // will be falsy
      }
    })
    
    document.addEventListener("keydown", this.onTypeBound);
  }
  
  cleanup() {
    document.removeEventListener("keydown", this.onTypeBound);
  }
  
  randomColorOr(or?: HexColorHash): HexColorHash | undefined { // in effect, or computed, will be triggered by the rainbow tick
    if (or == undefined) return or;
    if (!this.rainbow.tick.value) return or;
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}` as HexColorHash;
  }
})