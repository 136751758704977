class FetchResponse {
    private response: Response;
    private responseJson?: Promise<any>;
    private responseText?: Promise<string>;

    constructor(response: Response) {
        this.response = response;
    }
    get statusCode() {
        return this.response.status;
    }
    get redirected() {
        return this.response.redirected;
    }
    get ok() {
        return this.response.ok;
    }
    get unauthenticated() {
        return this.statusCode === 401;
    }
    get unprocessableEntity() {
        return this.statusCode === 422;
    }
    get authenticationURL() {
        return this.response.headers.get("WWW-Authenticate");
    }
    get contentType() {
        const contentType = this.response.headers.get("Content-Type") || "";
        return contentType.replace(/;.*$/, "");
    }
    get headers() {
        return this.response.headers;
    }
    get html() {
        if (this.contentType.match(/^(application|text)\/(html|xhtml\+xml)$/)) {
            return this.text;
        }
        return Promise.reject(new Error(`Expected an HTML response but got "${this.contentType}" instead`));
    }
    get json() {
        if (this.contentType.match(/^application\/.*json$/)) {
            return this.responseJson || (this.responseJson = this.response.json());
        }
        return Promise.reject(new Error(`Expected a JSON response but got "${this.contentType}" instead`));
    }
    get text() {
        return this.responseText || (this.responseText = this.response.text());
    }
    get isTurboStream() {
        return this.contentType.match(/^text\/vnd\.turbo-stream\.html/);
    }
    async renderTurboStream() {
        if (this.isTurboStream) {
            if (window.Turbo) {
                await window.Turbo.renderStreamMessage(await this.text);
            } else {
                console.warn("You must set `window.Turbo = Turbo` to automatically process Turbo Stream events with request.js");
            }
        } else {
            return Promise.reject(new Error(`Expected a Turbo Stream response but got "${this.contentType}" instead`));
        }
    }
}

class RequestInterceptor {
    private static interceptor?: (request: FetchRequest) => void;
    static register(interceptor: (request: FetchRequest) => void) {
        this.interceptor = interceptor;
    }
    static get() {
        return this.interceptor;
    }
    static reset() {
        this.interceptor = undefined;
    }
}

function getCookie(name: string | null) {
    if (!name) return null;
    const cookies = document.cookie ? document.cookie.split("; ") : [];
    const prefix = `${encodeURIComponent(name)}=`;
    const cookie = cookies.find((cookie => cookie.startsWith(prefix)));
    if (cookie) {
        const value = cookie.split("=").slice(1).join("=");
        if (value) {
            return decodeURIComponent(value);
        }
    }
}

function compact(object: { [key: string]: any }) {
    const result: { [key: string]: any } = {};
    for (const key in object) {
        const value = object[key];
        if (value !== undefined) {
            result[key] = value;
        }
    }
    return result;
}

function metaContent(name: string) {
    const element = document.head.querySelector<HTMLMetaElement>(`meta[name="${name}"]`);
    return element && element.content;
}

function stringEntriesFromFormData(formData: FormData) {
    // @ts-ignore
    return [ ...formData ].reduce<[string, FormDataEntryValue][]>(((entries, [name, value]) => entries.concat(typeof value === "string" ? [ [ name, value ] ] : [])), []);
}

function mergeEntries(searchParams: { [key: string]: any }, entries: [string, FormDataEntryValue | string][]) {
    for (const [name, value] of entries) {
        if (value instanceof window.File) continue;
        if (searchParams.has(name) && !name.includes("[]")) {
            searchParams.delete(name);
            searchParams.set(name, value);
        } else {
            searchParams.append(name, value);
        }
    }
}

type FetchRequestOptions = {
    redirect?: "follow" | "error" | "manual";
    signal?: AbortSignal | null;
    body?: ReadableStream | Blob | BufferSource | FormData | URLSearchParams | string;
    headers?: Record<string, string>;
    contentType?: string;
    query?: Record<string, string | FormDataEntryValue>;
    responseKind?: "json" | "html" | "any" | "turbo-stream";
};

class FetchRequest {
    method: string;
    originalUrl: string;
    options: FetchRequestOptions;
    constructor(method: string, url: URL | string, options: FetchRequestOptions = {}) {
        this.method = method;
        this.options = options;
        this.originalUrl = url.toString();
    }
    async perform() {
        try {
            const requestInterceptor = RequestInterceptor.get();
            if (requestInterceptor) {
                await requestInterceptor(this);
            }
        } catch (error) {
            console.error(error);
        }
        const response = new FetchResponse(await window.fetch(this.url, this.fetchOptions));
        if (response.unauthenticated && response.authenticationURL) {
            return Promise.reject(window.location.href = response.authenticationURL);
        }
        if (response.ok && response.isTurboStream) {
            await response.renderTurboStream();
        }
        return response;
    }
    addHeader(key: string, value: string) {
        const headers = this.additionalHeaders;
        headers[key] = value;
        this.options.headers = headers;
    }
    sameHostname() {
        if (!this.originalUrl.startsWith("http:")) {
            return true;
        }
        try {
            return new URL(this.originalUrl).hostname === window.location.hostname;
        } catch (_) {
            return true;
        }
    }
    get fetchOptions() : RequestInit {
        return {
            method: this.method.toUpperCase(),
            headers: this.headers,
            body: this.formattedBody,
            signal: this.signal,
            credentials: "same-origin",
            redirect: this.redirect
        };
    }
    get headers() : Record<string, string> {
        const baseHeaders: Record<string, string> = {
            "X-Requested-With": "XMLHttpRequest",
            Accept: this.accept
        };
        this.contentType && (baseHeaders["Content-Type"] = this.contentType);
        if (this.sameHostname()) {
            this.csrfToken && (baseHeaders["X-CSRF-Token"] = this.csrfToken);
        }
        return compact(Object.assign(baseHeaders, this.additionalHeaders));
    }
    get csrfToken() {
        return getCookie(metaContent("csrf-param")) || metaContent("csrf-token");
    }
    get contentType() {
        if (this.options.contentType) {
            return this.options.contentType;
        } else if (this.body == null || this.body instanceof window.FormData) {
            return undefined;
        } else if (this.body instanceof window.File) {
            return this.body.type;
        }
        return "application/json";
    }
    get accept() {
        switch (this.responseKind) {
            case "html":
                return "text/html, application/xhtml+xml";

            case "turbo-stream":
                return "text/vnd.turbo-stream.html, text/html, application/xhtml+xml";

            case "json":
                return "application/json, application/vnd.api+json";

            default:
                return "*/*";
        }
    }
    get body() {
        return this.options.body;
    }
    get query() {
        const originalQuery = (this.originalUrl.split("?")[1] || "").split("#")[0];
        const params = new URLSearchParams(originalQuery);
        const requestQuery = this.options.query;
        let requestQueryEntries: [string, FormDataEntryValue | string][];
        if (requestQuery instanceof window.FormData) {
            requestQueryEntries = stringEntriesFromFormData(requestQuery);
        } else if (requestQuery instanceof window.URLSearchParams) {
            requestQueryEntries = [...requestQuery.entries()];
        } else {
            requestQueryEntries = Object.entries(requestQuery || {});
        }
        mergeEntries(params, requestQueryEntries);
        const query = params.toString();
        return query.length > 0 ? `?${query}` : "";
    }
    get url() {
        return this.originalUrl.split("?")[0].split("#")[0] + this.query;
    }
    get responseKind() {
        return this.options.responseKind || "html";
    }
    get signal() {
        return this.options.signal;
    }
    get redirect() {
        return this.options.redirect || "follow";
    }
    get additionalHeaders() {
        return this.options.headers || {};
    }
    get formattedBody() {
        const bodyIsAString = Object.prototype.toString.call(this.body) === "[object String]";
        const contentTypeIsJson = this.headers["Content-Type"] === "application/json";
        if (contentTypeIsJson && !bodyIsAString) {
            return JSON.stringify(this.body);
        }
        return this.body;
    }
}

async function get(url: URL | string, options: { [key: string]: any } = {}) {
    const request = new FetchRequest("get", url, options);
    return request.perform();
}

async function post(url: URL | string, options: { [key: string]: any } = {}) {
    const request = new FetchRequest("post", url, options);
    return request.perform();
}

async function put(url: URL | string, options: { [key: string]: any } = {}) {
    const request = new FetchRequest("put", url, options);
    return request.perform();
}

async function patch(url: URL | string, options: { [key: string]: any } = {}) {
    const request = new FetchRequest("patch", url, options);
    return request.perform();
}

async function destroy(url: URL | string, options: { [key: string]: any } = {}) {
    const request = new FetchRequest("delete", url, options);
    return request.perform();
}

export { FetchRequest, FetchResponse, RequestInterceptor, destroy, get, patch, post, put };