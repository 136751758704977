import Memoizable from "./Memoizable";
import {getTarifsForCategoriePlace} from "../utils/tarif";
import {computed, effect, ReadonlySignal, signal, Signal} from "@preact/signals";

type PlaceDataWithTarif = PlaceData & {
  selected_tarif_id: Signal<string>
  price: ReadonlySignal<number>
}

export default Memoizable(class InteractiveStadiumPlacesList {
  initialized = false
  limit: number
  tarifs: Tarif[] // Tarif ne bougera pas une fois le premier chargement effectué.

  data = signal<PlaceDataWithTarif[]>([])
  length = computed(() => this.data.value.length)
  selectedPlaceIds = computed(() => this.data.value.map(p => p.placeLogique.id))
  limitReached: ReadonlySignal<boolean>
  limitReachedMessage = computed(() => this.limitReached.value ? `Vous avez dépassé la limite de places autorisée(Max: ${this.limit})` : undefined)
  totalPrice = computed(() => this.data.value.reduce((acc, p) => acc + p.price.value, 0))

  add(place: PlaceData, _selected_tarif_id?: string) {
    const tarifs = getTarifsForCategoriePlace(this.tarifs, place.placeLogique.categorie_place_id)
    const selected_tarif_id = signal(_selected_tarif_id ?? tarifs[0].id.toString())
    const price = computed(() => {
      const tarif = tarifs.find(t => t.id.toString() == selected_tarif_id.value) ?? tarifs[0]
      return (tarif.prix_ttc.cents || 0) / 100
    })
    if(this.data.value.some(p => p.placeLogique.id === place.placeLogique.id)) return
    this.data.value = [...this.data.value, { ...place, selected_tarif_id, price }]
  }

  remove(id: number) {
    this.data.value = this.data.value.filter(p => p.placeLogique.id !== id)
  }

  includes(id: number) {
    return this.data.value.some(p => p.placeLogique.id === id)
  }

  constructor(for_stockprint: boolean | undefined, user_key: string, limit: number = 0, tarifs: Tarif[]) {
    this.limit = limit
    this.tarifs = tarifs
    effect(() => {
      const storage_key = for_stockprint ? 'places-list' : 'local-cart'
      if (this.data.value.length || this.initialized) {
        localStorage.setItem(storage_key, JSON.stringify({
          places: this.data.value,
          expiration: Date.now() + 1000 * 60 * 15, // 15min
          user_key: user_key
        }))
      } else {
        const {places, expiration, user_key: _user_key} : {
          places: PlaceDataWithTarif[],
          expiration: number,
          user_key: string
        } = JSON.parse(localStorage.getItem(storage_key) ?? '{}')
        if (expiration >= Date.now() && user_key == _user_key) {
          for (const place of places) {
            this.add(place, place.selected_tarif_id as any as string) // here, no signal because stored value
          }
        } else {
          localStorage.removeItem(storage_key)
        }
        this.initialized = true
      }
    })

    this.limitReached = computed(() => limit == 0 ? false : this.data.value.length >= limit)
  }

})