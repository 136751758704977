import Memoizable from "./Memoizable";
import {MutableRef} from "preact/hooks";
import Tooltip from "../../controllers/components/tooltip";
import {debounce} from "../../lib/debounce_and_throttle";

export default Memoizable(class TooltipData {
  #instance: Tooltip | undefined;
  containerRef = {current: null} as MutableRef<HTMLDivElement | null>;
  debounceCancel = undefined as undefined | (() => void);
  
  init() {
    if (!this.containerRef.current) return
    this.#instance = new Tooltip(this.containerRef.current)
  }
  
  // the debounced function to show the tooltip
  showDebounced = debounce((el: SVGElement, data: string) => {
    this.#instance?.showOn(el).setHtml(data).fitIn().show()
  }, 100)
  
  // this function will show the tooltip on the element, with the data, debounced to avoid spamming the tooltip
  show(el: SVGElement, data: string) {
    this.debounceCancel = this.showDebounced(el, data)
  }
  
  // this function will hide the tooltip, and cancel the debounce if it's set
  hide() {
    this.#instance?.hide() && this.debounceCancel?.()
  }
})