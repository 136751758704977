import {useEffect, useState} from "react";
import {Signal, useComputed, useSignal} from "@preact/signals";
import {AreaSelectionType, MapProps, SelectionVisual} from "./InteractiveMap";
import MapEasteregg from "../classes/MapEasteregg";

interface MapPlaceProps {
  place: PlaceSVGData;
  zoomedZoneId: Signal<number | undefined>;
  selectedIds: Signal<number[]>;
  svgTransform: Signal<{ x: number, y: number, z: number }>;
  onClick: (el: SVGCircleElement, ev: MouseEvent) => void;
  onHover?: (el: SVGPathElement, leave: boolean, ev: MouseEvent) => void;
  selectionVisual: MapProps["selectionVisual"];
  areaSelectionType: Exclude<MapProps["areaSelectionType"], undefined>;
  mapEasteregg: InstanceType<typeof MapEasteregg>
}

export default function MapPlace({
  place,
  zoomedZoneId,
  selectedIds,
  onClick,
  svgTransform,
  onHover,
  selectionVisual,
  areaSelectionType,
  mapEasteregg
}: MapPlaceProps) {
  const [hovered, setHovered] = useState(false);
  const selected = useComputed(() => selectedIds.value.includes(place.id));
  const classes = useComputed(() =>
    (selectedIds.value?.includes(place.id) && selectionVisual == SelectionVisual.CLASS_NAME ? " selected" : "") +
    (
      areaSelectionType.value == AreaSelectionType.ZONES_AND_PLACES ||
      areaSelectionType.value == AreaSelectionType.ZONES_OR_PLACES && zoomedZoneId.value
        ? " selectable" : ""
    )
  )
  // we transform the fill_color into a signal to be able to update it into the useComputed
  const fill_color = useSignal<HexColorHash | undefined>(place.fill_color)
  useEffect(() => fill_color.value = place.fill_color, [place.fill_color])
  
  const fill = useComputed(() => mapEasteregg.randomColorOr(fill_color.value))
  
  if (window.debug) console.log("rendered map place")
  return <>
    {/* Placed behind the place because we want only to display the border */}
    {selected.value && <>
      <circle cx={place.svg_cx} cy={place.svg_cy} r={place.svg_r}
              className={"pointer-events-none !stroke-info"}
              style={{filter: "none", strokeWidth: 40 / svgTransform.value.z}}/>
      <circle cx={place.svg_cx} cy={place.svg_cy} r={place.svg_r}
              className={"pointer-events-none !stroke-background"}
              style={{filter: "none", strokeWidth: 20 / svgTransform.value.z}}/>
    </>}
    
    <circle data-place-id={place.id} cx={place.svg_cx} cy={place.svg_cy} r={place.svg_r}
            className={(place.disponible ? "disponible" : "!cursor-default") + classes.value}
            onClick={(e) => onClick(e.target as SVGCircleElement, e)}
            fill={fill}
            onMouseEnter={(e) => {
              setHovered(true)
              onHover?.(e.target as SVGPathElement, false, e)
            }}
            onMouseLeave={(e) => {
              setHovered(false)
              onHover?.(e.target as SVGPathElement, true, e)
            }}
    />
    
    {hovered && selectionVisual == SelectionVisual.OVERLAY &&
      <circle cx={place.svg_cx} cy={place.svg_cy} r={place.svg_r}
              className={"pointer-events-none fill-info fill-opacity-20 !stroke-info"}
              style={{
                filter: "none",
                strokeWidth: 8 / svgTransform.value.z
              }}/>}
  </>
}