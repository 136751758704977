import {computed, ReadonlySignal, Signal, signal} from "@preact/signals";
import Memoizable from "./Memoizable";
import ConfiguratorData from "./ConfiguratorData";
import {JSXInternal} from "preact/src/jsx";
import SelectOptions, {selectOptionsFrom} from "../subs/SelectOptions";
import {type} from "node:os";
type ReadonlySignalOr<T> = ReadonlySignal<T> | T

/**
 * Will get the common value of a field from the selected elements, or "" if they differ
 * Will contain if there is multiple values, or not using isMultiple
 */
export type FormFieldData<T> = {
  id: string,
  opts: JSXInternal.Element,
  value: Signal<T | "">,
  isMultiple: Signal<boolean>,
  label: string,
  class: ReadonlySignalOr<string>,
}

export default Memoizable(class ConfiguratorFormData {
  mapData: InstanceType<typeof ConfiguratorData>
  couleur_zone_mixe: HexColorHash
  data = {
    disponible: {value: signal(""), isMultiple: signal(false)} as any as FormFieldData<BooleanString>,
    categorie_place_id: {value: signal(""), isMultiple: signal(false)} as any  as FormFieldData<NumericString>,
    reservoir_id: {value: signal(""), isMultiple: signal(false)} as any  as FormFieldData<NumericString>,
    couleur_principale: {value: signal(""), isMultiple: signal(false)} as any  as FormFieldData<HexColorHash>,
  }


  multOrPlaceholder(key: keyof typeof this.data) {
    return computed(() =>
      this.data[key].isMultiple.value
        ? "<Multiple>" : window.I18n.t(`activemodel.placeholders.configuration_stade.${key}`)
    )
  }

  couleurAvailable() {
    return this.data.categorie_place_id.isMultiple.value &&
        this.mapData.selectedZones.value.length === 1
  }

  couleurPrincipaleOpts(_categories: (SelectOption & { couleur: HexColorHash })[]) {
    const categories = _categories.map(c => ({...c, value: c.value.toString()}))
    return computed(() =>
        this.couleurAvailable()
        ? [
          {value: this.couleur_zone_mixe, label: "Couleur zone mixte", selected: this.data.couleur_principale.value.value === this.couleur_zone_mixe || undefined},
          ...categories
            .filter(c => this.mapData.selectedZones.value[0].categorie_place_ids.includes(c.value as NumericString))
            .map(c => ({
              value: c.couleur,
              label: "Couleur catégorie: " + c.label,
              selected: this.data.couleur_principale.value.value === c.couleur || undefined
            })),
        ] : []
    )
  }
  
  constructor(
    mapData: InstanceType<typeof ConfiguratorData>,
    couleur_zone_mixe: HexColorHash,
    options: {
      disponibles: SelectOption[],
      categories: (SelectOption & { couleur: HexColorHash })[],
      reservoirs: SelectOption[],
    }
  ) {
    this.mapData = mapData
    this.couleur_zone_mixe = couleur_zone_mixe
    this.data.disponible.opts = selectOptionsFrom(options.disponibles, this.multOrPlaceholder("disponible"))
    this.data.categorie_place_id.opts = selectOptionsFrom(options.categories, this.multOrPlaceholder("categorie_place_id"))
    this.data.reservoir_id.opts = selectOptionsFrom(options.reservoirs, this.multOrPlaceholder("reservoir_id"))
    this.data.couleur_principale.opts = selectOptionsFrom(this.couleurPrincipaleOpts(options.categories), this.multOrPlaceholder("couleur_principale"))

    for(const key in this.data) {
      this.data[key as keyof typeof this.data].label = window.I18n.t(`activemodel.attributes.configuration_stade.${key}`)
      this.data[key as keyof typeof this.data].id = `configurator_form_${key}`
      this.data[key as keyof typeof this.data].class = "field"
    }

    this.data.couleur_principale.class = computed(() =>
      this.mapData.selectedZones.value.length === 1 &&
      this.data.categorie_place_id.isMultiple.value
        ? "field" : "hidden"
    )
  }
  
  // This function get a formatted data for a field from provided array of objects, using the key
  // it will return the common value, or "" if they differ, and if there is multiple values, or not using isMultiple
  alterDataFromCommonAttr<T, K extends keyof T>(array: T[], key: K, dataKey: keyof typeof this.data) {
    const vs = Array.isArray(array[0]?.[key]) ? array.map(e => e[key]).flat() : array.map(e => e[key]) as any
    const allEqual = vs.every((v: any) => v === vs[0]) // if all are equal, it's not multiple
    const isMultiple = !allEqual && vs.length > 1 // if all are equal, it's not multiple, if there is only one value, it's not multiple
    this.data[dataKey].value.value = allEqual ? vs[0] ?? "" : ""
    this.data[dataKey].isMultiple.value = isMultiple
  }
  
  // This function will alter the form data from current selection, we accept only selection from places if zone
  // is zoomed, from zones if no zone is zoomed
  alterFromSelection() {
    if(window.debug) console.log("altering form data from selection")
    const elems: ConfiguratorStateableElement[] = this.mapData.zoomedZoneId.value
      ? this.mapData.selectedPlaces.value : this.mapData.selectedZones.value
    this.alterDataFromCommonAttr(elems, "disponibles", "disponible")
    this.alterDataFromCommonAttr(elems, "reservoir_ids", "reservoir_id")
    this.alterDataFromCommonAttr(elems, "categorie_place_ids", "categorie_place_id")
    this.alterDataFromCommonAttr(elems, "couleur_principale", "couleur_principale")
  }
  
  // this will format data to send to the server
  get dataToSend() {
    const isZoomed = this.mapData.zoomedZoneId.value !== undefined
    type disponible = typeof this.data.disponible.value.value
    type categorie_place_id = typeof this.data.categorie_place_id.value.value
    type reservoir_id = typeof this.data.reservoir_id.value.value
    type couleur_principale = typeof this.data.couleur_principale.value.value
    const data: {
      disponible: disponible,
      categorie_place_id: categorie_place_id,
      reservoir_id: reservoir_id,
      place_logique_ids?: number[],
      zone_logique_ids?: number[],
      couleur_principale?: couleur_principale,
    } = {
      disponible: this.data.disponible.value.value,
      categorie_place_id: this.data.categorie_place_id.value.value,
      reservoir_id: this.data.reservoir_id.value.value,
      couleur_principale: this.couleurAvailable() ? this.data.couleur_principale.value.value : undefined,
    }
    if (isZoomed) data.place_logique_ids = this.mapData.selectedPlaceIds.value
    else data.zone_logique_ids = this.mapData.selectedZoneIds.value
    
    return data
  }
})